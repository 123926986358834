<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="@/assets/logo-cycle.png" class="logo">
      </a>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                {{ name }}
              </a>

              <div class="navbar-dropdown">
                <a class="navbar-item" @click="closeSession">
                  Cerrar sesión
                </a>
                <!-- <hr class="navbar-divider"> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      name: null
    }
  },
  methods: {
    closeSession () {
      localStorage.removeItem('access-token')
      localStorage.removeItem('name')
      this.$router.replace('/login')
    }
  },
  async mounted () {
    this.name = await localStorage.getItem('name')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
    padding: 0 1rem;
    background: #0019A8;
  }

  img.logo {
    max-width: 80px;
    object-fit: cover;
  }

  a {
    font-size: 16px;
}
.navbar-link {
    color: #FFF !important;
}

  .navbar-link:not(.is-arrowless)::after {
    display: none;
  }

  .toggle-menu {
    cursor: pointer;
  }

  .navbar-menu .navbar-dropdown .navbar-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .navbar-item img {
    max-height: 70px;
  }

  .navbar-menu .navbar-dropdown .navbar-item i {
    padding-right: 4px;
  }

  .navbar-burger {
    height: inherit;
  }

  .navbar-link:not(.is-arrowless) {
    padding-right: .5em;
    padding-left: .5em;
  }

  .navbar-item .image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 35px;
  }

  .navbar-dropdown {
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    border-top: 0px solid #36475b3d;
  }

  a.navbar-item,
  .navbar-link,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: transparent !important;
    color: inherit;
  }

  .notify_container {
    border-left: 2px solid #eceff7;
    border-right: 2px solid #eceff7;
  }

  .notify_msg_container {
    min-width: 300px;
    max-width: 300px;
  }
  .notify_msg_content {
    white-space: normal;
  }

  .notify_icon {
    color: #35465a;
    font-size: 30px;
  }

  .notify_msg_content > *, .profile_content p, .profile_content {
    font-size: 13px;
  }

  .hr_profile {
    margin: 0;
  }

  @media screen and (max-width: 1023px) {
    .navbar {
      display: flex;
      justify-content: center;
      flex-direction: column;
      // padding: 1.5rem;
    }
    .navbar-menu {
      background-color: transparent;
    }
    .navbar-dropdown {
      box-shadow: none;
    }
    .navbar-link:not(.is-arrowless)::after {
      display: none;
    }
    .navbar-link:focus-within:not(.is-arrowless) {
      padding: 0.5rem 0.75rem;
    }
    .navbar-dropdown .navbar-item, .navbar-link {
      padding: 0.5rem 0.75rem;
    }
  }
  .logo {
    width: 50px;
  }
</style>
